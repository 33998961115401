const getCookie = (name: string) => {
  const match = document.cookie.match(
    new RegExp('(^|;\\s*)(' + name + ')=([^;]*)')
  )
  return match ? decodeURIComponent(match[3]) : null
}

export const useCsrfToken = async () => {
  const config = useRuntimeConfig()

  await $fetch('/sanctum/csrf-cookie', {
    baseURL: config.public.API_URL,
    credentials: 'include',
  })

  return getCookie('XSRF-TOKEN')
}
