export const api = async (url: string, options: any, locale = 'de') => {
  const config = useRuntimeConfig()
  const csrfToken = await useCsrfToken()

  await $fetch(`${config.public.API_URL}/api${url}`, {
    ...options,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Accept-Language': locale,
      Origin: config.public.APP_URL,
      ...(csrfToken && { 'X-XSRF-TOKEN': csrfToken }),
      ...options.headers,
    },
  })
}
